import jsonServerProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
import { buildAuthorizationHeader } from "./AuthProvider";

const container = document.getElementById("root");
const apiUrl = container.dataset.apiUrl;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        // eslint-disable-next-line no-param-reassign
        options.headers = new Headers();
    }

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    options.headers.set("Authorization", buildAuthorizationHeader({ username, password }));

    return fetchUtils.fetchJson(url, options);
};

const defaultProvider = jsonServerProvider(apiUrl, httpClient);

const dataProvider = {
    ...defaultProvider,

    getOrganizationKPIs: async (organizationId) => {
        const response = await httpClient(`${apiUrl}/organizations/${organizationId}/kpi`, { method: "GET" });
        return response.json;
    },
    getList: async (resource, params) => {
        // we add custom KPIS to organization list
        if (resource === "organizations") {
            const { data, total } = await defaultProvider.getList(resource, params);

            const response = await httpClient(`${apiUrl}/organizations/kpis`, {
                method: "POST",
                body: JSON.stringify({ organizationIds: data.map(({ id }) => id) }),
            });

            const completeData = data.map((orga) => ({ ...orga, ...response.json[orga.id] }));

            return { data: completeData, total };
        }

        return defaultProvider.getList(resource, params);
    },
};
export default dataProvider;
