import {
    Datagrid,
    TextField,
    EditButton,
    List,
    SearchInput,
    FilterButton,
    TopToolbar,
    ExportButton,
    TextInput,
} from "react-admin";

const organizationFilters = [
    <SearchInput source="name" placeholder="Rechercher" alwaysOn />,
    <TextInput label="Kilomètres crédités" source="previsionalKm" type="number" />,
];

const OrganizationListActions = () => (
    <TopToolbar direction="row" justifycontent="space-between">
        <div>
            <FilterButton filters={organizationFilters} disableSaveQuery />
            <ExportButton />
        </div>
    </TopToolbar>
);

const OrganizationList = (props) => (
    // sort is default order, will be overriden by user on first requested sort
    <>
        <List
            id="list"
            {...props}
            sort={{ field: "createdAt", order: "DESC" }}
            actions={<OrganizationListActions />}
            filters={organizationFilters}
        >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="name" />
                <TextField source="previsionalKm" label="Kilomètres crédités" />
                <TextField source="totalKm" label="Kilomètres scannés" sortable={false} />
                <TextField source="analysedKm" label="Kilomètres analysés" sortable={false} />
                <EditButton />
            </Datagrid>
        </List>
    </>
);

export default OrganizationList;
