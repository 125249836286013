import { TextField } from "react-admin";

const CustomTextField = ({ record, source, label, style, customStyle }) => (
    <>
        <p style={{ ...style, ...customStyle }}>{label}</p>
        <TextField source={source} record={record}></TextField>
    </>
);

export default CustomTextField;
