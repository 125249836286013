import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const container = document.getElementById("root");

ReactDOM.render(
    <React.StrictMode>
        <App {...container.dataset} />
    </React.StrictMode>,
    container,
);
