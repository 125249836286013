import * as React from "react";
import { email, AutocompleteInput, Edit, required, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import { inputSx } from "./style";

const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm title={`${props.firstName} ${props.lastName}`}>
            <TextInput source="id" sx={inputSx} disabled />
            <TextInput source="firstName" sx={inputSx} validate={required()} />
            <TextInput source="lastName" sx={inputSx} validate={required()} />
            <TextInput source="email" sx={inputSx} validate={[required(), email()]} />
            <ReferenceInput reference="organizations" source="organization.id">
                <AutocompleteInput
                    alwaysOn
                    filterToQuery={(val) => ({ name: val })}
                    label="Organization"
                    optionText="name"
                    sx={inputSx}
                    validate={required()}
                />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default UserEdit;
