import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import { inputSx } from "../users/style";

// By default save & delete buttons are displayed in the Edit page. We can choose to only display the save button by creating a custom toolbar.

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton label="Enregistrer" />
    </Toolbar>
);

const OrganizationEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} title={`${props.name}`}>
            <TextInput sx={inputSx} source="name" label="Nom de l'organisation" />
            <TextInput sx={inputSx} source="previsionalKm" label="Kilomètres crédités" />
        </SimpleForm>
    </Edit>
);

export default OrganizationEdit;
