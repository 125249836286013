import { format } from "date-fns";
import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    FunctionField,
    List,
    SearchInput,
    TextField,
    TextInput,
    TopToolbar,
} from "react-admin";

const renderDate = ({ createdAt }) => {
    if (!createdAt) return "";

    return format(new Date(createdAt), "dd/MM/yyyy");
};

const usersFilters = [
    <SearchInput source="lastName" placeholder="Rechercher par nom" alwaysOn />,
    <TextInput label="Prénom" source="firstName" type="string" />,
    <TextInput label="Email" source="email" type="string" />,
    <TextInput label="Organisation" source="organization.name" type="string" />,
];

const UsersListActions = () => {
    return (
        <TopToolbar direction="row" justifycontent="space-between">
            <div>
                <FilterButton filters={usersFilters} disableSaveQuery />
                <ExportButton />
                <CreateButton />
            </div>
        </TopToolbar>
    );
};

const UserList = (props) => (
    // sort is default order, will be overriden by user on first requested sort
    <List
        {...props}
        title="Utilisateurs"
        actions={<UsersListActions />}
        filters={usersFilters}
        sort={{ field: "firstName", order: "ASC" }}
    >
        <Datagrid>
            <TextField label="Nom" source="lastName" />
            <TextField label="Prénom" source="firstName" />
            <TextField label="Email" source="email" />
            <TextField label="Organisation" source="organization.name" />
            <FunctionField label="Crée le" source="createdAt" render={renderDate} />
            <EditButton />
        </Datagrid>
    </List>
);

export default UserList;
