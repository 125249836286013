import { Show, SimpleShowLayout, TextField } from "react-admin";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dataProvider from "../../providers/dataProvider";
import CustomTextField from "../../components/CustomTextField";

const style = {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "0.75em",
    marginBottom: "0.2em",
};

const OrganizationShow = () => {
    const { id } = useParams();

    const [organizationKpis, setOrganizationKpis] = useState(null);
    useEffect(() => {
        const fetchOrganizationKpis = async () => {
            const data = await dataProvider.getOrganizationKPIs(id);
            setOrganizationKpis(data);
        };

        fetchOrganizationKpis();
    }, [id]);

    return (
        <Show>
            <SimpleShowLayout spacing={3}>
                <TextField source="name" label="Nom de l'organisation" />
                <TextField source="previsionalKm" label="Kilomètres crédités" />
                {organizationKpis && (
                    <div style={{ marginTop: "-9px" }}>
                        <CustomTextField
                            record={organizationKpis}
                            source="totalKm"
                            label="Kilomètres scannés"
                            style={style}
                        />
                        <CustomTextField
                            record={organizationKpis}
                            source="analysedKm"
                            label="Kilomètres analysés"
                            style={style}
                            customStyle={{ marginTop: "24px" }}
                        />
                    </div>
                )}
            </SimpleShowLayout>
        </Show>
    );
};

export default OrganizationShow;
