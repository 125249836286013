import * as React from "react";
import { email, Create, ReferenceInput, required, AutocompleteInput, SimpleForm, TextInput } from "react-admin";
import { inputSx } from "./style";

const UserCreate = (props) => (
    <Create title="Créer un utilisateur" {...props}>
        <SimpleForm redirect="list">
            <TextInput source="firstName" sx={inputSx} validate={required()} />
            <TextInput source="lastName" sx={inputSx} validate={required()} />
            <TextInput source="email" sx={inputSx} validate={[required(), email()]} />
            <ReferenceInput reference="organizations" source="organization">
                <AutocompleteInput
                    alwaysOn
                    filterToQuery={(val) => ({ name: val })}
                    optionText="name"
                    label="Organization"
                    sx={inputSx}
                    validate={required()}
                />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default UserCreate;
