import PropTypes from "prop-types";
import React from "react";
import { MenuItemLink } from "react-admin";

const Menu = ({ onMenuClick }) => {
    const resources = [
        { name: "Utilisateurs", to: "users" },
        { name: "Organisations", to: "organizations" },
    ];

    return (
        <div>
            {resources.map((resource) => (
                <MenuItemLink
                    key={resource.name}
                    onClick={onMenuClick}
                    primaryText={resource.name}
                    to={`/${resource.to}`}
                    sidebarIsOpen
                />
            ))}
        </div>
    );
};

Menu.propTypes = {
    onMenuClick: PropTypes.func.isRequired,
};

export default Menu;
