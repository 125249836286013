import PropTypes from "prop-types";
import { React } from "react";
import { Admin, Resource } from "react-admin";
import { ConfigProvider } from "./components/ConfigContext";
import MyLayout from "./components/Layout";
import AuthProvider from "./providers/AuthProvider";
import { UserCreate, UserEdit, UserList } from "./resources/users";
import { OrganizationList, OrganizationEdit, OrganizationShow } from "./resources/organizations";
import dataProvider from "./providers/dataProvider";

const App = ({ apiUrl }) => (
    <ConfigProvider value={{ apiUrl }}>
        <Admin authProvider={new AuthProvider(apiUrl)} dataProvider={dataProvider} layout={MyLayout}>
            <Resource create={UserCreate} edit={UserEdit} list={UserList} name="users" />
            <Resource list={OrganizationList} edit={OrganizationEdit} show={OrganizationShow} name="organizations" />
        </Admin>
    </ConfigProvider>
);

App.propTypes = {
    apiUrl: PropTypes.string.isRequired,
};

export default App;
