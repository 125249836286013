const buildAuthorizationHeader = ({ username, password }) => `Basic ${btoa(`${username}:${password}`)}`;

class AuthProvider {
    constructor(apiUrl) {
        this.apiUrl = apiUrl;
    }

    async login({ username, password }) {
        const response = await fetch(`${this.apiUrl}/check-auth`, {
            headers: {
                Authorization: buildAuthorizationHeader({ username, password }),
            },
        });

        if (response.status !== 200) {
            throw new Error("Invalid credentials");
        }

        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
    }

    async checkError(error) {
        if (error.status === 401 || error.status === 403) {
            await this.logout();

            throw new Error();
        }
    }

    async checkAuth() {
        if (!localStorage.getItem("username") || !localStorage.getItem("password")) {
            throw new Error();
        }
    }

    async logout() {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
    }

    async getIdentity() {
        return null;
    }

    async getPermissions() {
        return null;
    }
}

export { buildAuthorizationHeader };
export default AuthProvider;
